import { render, staticRenderFns } from "./comision_por_empleados.vue?vue&type=template&id=15b15f33"
import script from "./comision_por_empleados.vue?vue&type=script&lang=js"
export * from "./comision_por_empleados.vue?vue&type=script&lang=js"
import style0 from "./comision_por_empleados.vue?vue&type=style&index=0&id=15b15f33&prod&scope=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports